import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuDrawer from "./MenuDrawer";
import { useNavigate } from "react-router-dom";

function MenuAppBar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              console.log("Clicked on menu");
              toggleDrawer(true);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ "&:hover": {cursor: "pointer"} }} onClick={() => navigate('/')}>
            Toolgarden
          </Typography>
        </Toolbar>
      </AppBar>
      <MenuDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
    </>
  );
}

export default MenuAppBar;
