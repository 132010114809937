import { Lock, Timer } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LinkProps } from "react-router-dom";

type Props = {
  drawerOpen: boolean;
  toggleDrawer: (open: boolean) => void;
};

type ToolEntry = {
  name: string;
  icon: any;
  path: string;
};

const toolEntries: ToolEntry[] = [
  {
    name: "Password Generator",
    icon: <Lock />,
    path: "/password-generator",
  },
  {
    name: "Stopwatch",
    icon: <Timer />,
    path: "/stopwatch",
  },
];

function MenuDrawer({ drawerOpen, toggleDrawer }: Props) {
  return (
    <Box>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => {
          toggleDrawer(false);
        }}
      >
        <List>
          {toolEntries.map((entry, idx) => (
            <ListItem key={entry.path} disablePadding>
              <ListItemButton component={Link} to={entry.path}>
                <ListItemIcon>{entry.icon}</ListItemIcon>
                <ListItemText primary={entry.name} />
              </ListItemButton>
            </ListItem>
          ))}
          <Divider />
        </List>
      </Drawer>
    </Box>
  );
}

export default MenuDrawer;
