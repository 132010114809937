import { Cached, ContentCopy } from "@mui/icons-material";
import {
  Button,
  Chip,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  LinearProgress,
  Slider,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MenuAppBar from "../components/MenuAppBar";
import "./PasswordGenerator.css";

type PwSettings = {
  uppercase: boolean;
  lowercase: boolean;
  digits: boolean;
  specialChars: boolean;
  readable: boolean;
  length: number;
};

const initialPwSettings: PwSettings = {
  uppercase: true,
  lowercase: true,
  digits: false,
  specialChars: false,
  readable: false,
  length: 16
};

function PasswordGenerator() {
  const [password, setPassword] = useState("");
  const pwInput = useRef<HTMLInputElement>(null);
  const [pwSettings, setPwSettings] = useState<PwSettings>(initialPwSettings);

  function getColor(value: number): string {
    const hue = (value * 120).toString(10);
    return ["hsl(", hue, ",100%,50%)"].join("");
  }

  function pwStrength(): number {
    let score = 0;
    score += pwSettings.length
    if (pwSettings.uppercase) {
      score *= 1.6
    }
    if (pwSettings.lowercase) {
      score *= 1.6
    }
    if (pwSettings.digits) {
      score *= 1.4
    }
    if (pwSettings.specialChars) {
      score *= 2.2
    }
    if (pwSettings.readable) {
      score *= 0.8
    }
    score = Math.floor(score)

    return (score >= 100) ? 100 : score;
  }

  useEffect(() => {
    calculatePw();
  }, []);

  useEffect(() => {
    calculatePw();
  }, [pwSettings]);

  const calculatePw = () => {
    const lowerCases = "abcdefghijklmnopqrstuvwxyz";
    const upperCases = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const digits = "0123456789";
    const specialChars = "!\"§$%&/()=?{[]}\\*';:_,.-@€<|>";
    const specialCharsReadable = "+-#?$@";
    const array = new Uint32Array(pwSettings.length);
    crypto.getRandomValues(array);

    let pool = "";
    if (pwSettings.lowercase) {
      pool += lowerCases;
    }

    if (pwSettings.uppercase) {
      pool += upperCases;
    }

    if (pwSettings.digits) {
      pool += digits;
    }

    if (pwSettings.specialChars) {
      if (pwSettings.readable) {
        pool += specialCharsReadable;
      } else {
        pool += specialChars;
      }
    }

    if (pwSettings.readable) {
      pool = pool.replace(/[icCdDbqQlIjJoO01! ]/g, "");
    }

    let result = "";
    array.forEach((int) => {
      result += pool.charAt(int % pool.length);
    });

    setPassword(result);
  };

  const handlePwChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLengthChange = (event: Event, value: number | number[]) => {
    if (!Array.isArray(value)) {
      setPwSettings({
        ...pwSettings,
        length: value,
      });
    }
  };

  const copyPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    pwInput.current?.select();
    void navigator.clipboard.writeText(password);
  };

  return (
    <>
      <MenuAppBar />
      <Container maxWidth="md" sx={{ marginTop: "2rem", textAlign: "center" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ textTransform: "uppercase" }} variant="h4">
              Password generator
            </Typography>
            <Typography variant="subtitle1">
              Use this password generator to generate a strong and secure
              password.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 5, mb: 5 }}>
            <Stack direction="row" sx={{ justifyContent: "center" }}>
              <input
                id="PasswordInput"
                type="text"
                spellCheck={false}
                ref={pwInput}
                value={password}
                onChange={handlePwChange}
              />
              <IconButton onClick={copyPassword}>
                <ContentCopy />
              </IconButton>
              <IconButton
                onClick={() => {
                  calculatePw();
                }}
              >
                <Cached />
              </IconButton>
            </Stack>
            <LinearProgress
              variant="determinate"
              value={pwStrength()}
              sx={{
                mt: 1,
                "& .MuiLinearProgress-bar1Determinate": {
                  backgroundColor: getColor(pwStrength() / 100),
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider>
              <Chip label="Composition" />
            </Divider>
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={pwSettings.lowercase}
                    onChange={() => {
                      setPwSettings({
                        ...pwSettings,
                        lowercase: !pwSettings.lowercase,
                      });
                    }}
                  />
                }
                label="abc.."
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={pwSettings.uppercase}
                    onChange={() => {
                      setPwSettings({
                        ...pwSettings,
                        uppercase: !pwSettings.uppercase,
                      });
                    }}
                  />
                }
                label="ABC.."
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={pwSettings.specialChars}
                    onChange={() => {
                      setPwSettings({
                        ...pwSettings,
                        specialChars: !pwSettings.specialChars,
                      });
                    }}
                  />
                }
                label="!?#+*"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={pwSettings.digits}
                    onChange={() => {
                      setPwSettings({
                        ...pwSettings,
                        digits: !pwSettings.digits,
                      });
                    }}
                  />
                }
                label="123"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={pwSettings.readable}
                    onChange={() => {
                      setPwSettings({
                        ...pwSettings,
                        readable: !pwSettings.readable,
                      });
                    }}
                  />
                }
                label="Readable"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Divider>
              <Chip label="Length" />
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <Slider
              size="small"
              value={pwSettings.length}
              aria-label="Small"
              valueLabelDisplay="auto"
              valueLabelFormat={`Length: ${pwSettings.length}`}
              onChange={handleLengthChange}
              min={1}
              max={32}
            />
          </Grid>
          <Grid item xs={12}>
            <Button></Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default PasswordGenerator;
