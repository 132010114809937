import { Container, Grid } from "@mui/material";
import React from "react";
import MenuAppBar from "../components/MenuAppBar";
import ToolCard from "../components/ToolCard";

function Root() {
  return (
    <div>
      <MenuAppBar />
      <Container>
      <Grid container spacing={2} padding={2}>
          <Grid item md={4} sm={6} xs={12}>
            <ToolCard title="Password Generator" subtitle="Interactive tool" description="Generate secure passwords." linkTo="password-generator" />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <ToolCard title="Stopwatch" subtitle="Interactive tool" description="Measure time intervals." linkTo="stopwatch" />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <ToolCard title="Stopwatch" subtitle="Interactive tool" description="Measure time intervals." linkTo="stopwatch" />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <ToolCard title="Stopwatch" subtitle="Interactive tool" description="Measure time intervals." linkTo="stopwatch" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Root;
