import { Button, ButtonGroup, Container } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import MenuAppBar from '../components/MenuAppBar'

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

const draw = (ctx: CanvasRenderingContext2D, elapsedTime: number) => {
    ctx.strokeStyle = '#707070';
    ctx.lineWidth = 12;
    ctx.shadowBlur= 0;
    ctx.shadowColor = '#707070'

    function degToRad(degree: number){
      const factor = Math.PI/180;
      return degree*factor;
    }

    function renderTime(ctx: CanvasRenderingContext2D){
      const hrs = Math.floor(elapsedTime / (3600 * 1000));
      const min = Math.floor((elapsedTime / (60 * 1000)) % 60);
      const sec = Math.floor((elapsedTime / (1000)) % 60);
      const mil = Math.floor(elapsedTime % 1000);
      const smoothsec = sec+(mil/1000);
      const smoothmin = min+(smoothsec/60);

      //Background
      const gradient = ctx.createRadialGradient(250, 250, 5, 250, 250, 200);
      gradient.addColorStop(0, "#333333");
      gradient.addColorStop(1, "#121212");
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, 500, 500);

      // // Seconds
      // ctx.strokeStyle = "#666666"
      // ctx.beginPath();
      // ctx.arc(250,250,200, degToRad(270), degToRad(270+((36/100)*mil)));
      // ctx.stroke();

      //Minutes
      ctx.strokeStyle = "#474747"
      ctx.beginPath();
      ctx.arc(250,250,170, degToRad(270), degToRad(270+(smoothsec*6)));
      ctx.stroke();

      //Hours
      ctx.strokeStyle = "#333333"
      ctx.beginPath();
      ctx.arc(250,250,140, degToRad(270), degToRad(270+(smoothmin*6)));
      ctx.stroke();

      //Time
      ctx.font = "25px Consolas";
      ctx.fillStyle = '#EAE0CC';
      ctx.fillText(`${zeroPad(hrs, 2)}:${zeroPad(min, 2)}:${zeroPad(sec, 2)}:${zeroPad(mil, 3)}`, 170, 260);
    }
    renderTime(ctx)
}

interface ClockProps {
  draw: any;
  time: number;
}

const Clock = (props: ClockProps) => {
  const { draw, time, ...rest } = props;
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current as unknown as HTMLCanvasElement
    const context = canvas.getContext('2d') as CanvasRenderingContext2D
    let animationFrameId: number
    
    const render = () => {
      draw(context, time)
      animationFrameId = window.requestAnimationFrame(render)
    }
    render()
    
    return () => {
      window.cancelAnimationFrame(animationFrameId)
    }
}, [time])

  return (
    <canvas ref={canvasRef} id="canvas" width="500" height="500" >cccc</canvas>
  )
}

function Stopwatch() {
    const canvasRef = useRef(null)
    const [time, setTime] = useState(0);
    const [startTime, setStartTime] = useState(0);
    const [running, setRunning] = useState(false);
    
    useEffect(() => {
      if (running) {
        const interval = setInterval(() => {
          if (running) {
            setTime((Date.now() - startTime));
          }
        }, 100);
        return () => clearInterval(interval);  
      }
    }, [running, startTime])

    const startClock = () => {
      if (!running) {
        // Don't count time while stopwatch is paused.
        setStartTime(Date.now() - time)
      }
      setRunning(!running);
    }

    const pauseClock = () => {
      setRunning(false);
    }

    const stopClock = () => {
      setStartTime(Date.now());
      setTime(0);
    }

  return (
    <>
        <MenuAppBar />
        <Container maxWidth="md" sx={{ marginTop: "2rem", textAlign: "center" }}>
            <Clock draw={draw} time={time} />
        </Container>
        <Container maxWidth="md" sx={{ textAlign: "center" }}>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button onClick={startClock}>⏯️</Button>
            <Button onClick={stopClock}>⏹️</Button>
        </ButtonGroup>
        </Container>
    </>
  )
}

export default Stopwatch