import { Box, Card, CardActionArea, CardActions, CardContent, Typography } from '@mui/material';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

interface ToolCardProps {
  title: string;
  subtitle?: string;
  description?: string;
  linkTo: string;
}

function ToolCard({title, subtitle, description, linkTo}: ToolCardProps) {
  const navigate = useNavigate();
  const handleClick = () => navigate(linkTo);

  return (
    <Card sx={{width: 260, margin: "auto"}}>
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {subtitle}
          </Typography>
          <Typography variant="body2">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default ToolCard